
import { Component, Vue, Watch } from "vue-property-decorator";

import Multiselect from "vue-multiselect";

import StartGroup from "../../models/sportevent/StartGroup";
import Discipline from "../../models/sporteventCalculation/Discipline";
import Jury from "../../models/sportevent/Jury";
import JuryService from "../../services/sportevent/JuryService";
import SporteventCompetition from "../../models/sportevent/SporteventCompetition";
import DisciplineCategory from "../../models/sporteventCalculation/DisciplineCategory";
import StartGroupService from "../../services/sportevent/StartGroupService";
import SporteventService from "../../services/sportevent/SporteventService";
import StarterService from "../../services/sportevent/StarterService";
import StartBlock from "../../models/sportevent/StartBlock";
import SporteventCompetitionService from "../../services/sportevent/SporteventCompetitionService";
import DisciplineService from "@/services/sporteventCalculation/DisciplineService";
import Sportevent from "@/models/sportevent/Sportevent";
import ListScoringSheetTableComponent from "./ListScoringSheetTable.vue";

@Component({
  components: {
    Multiselect,
    ListScoringSheetTableComponent,
  },
})
export default class ListScoringSheetsByDisciplineCategoryComponent extends Vue {

  public loading = true;

  public sportevent?: Sportevent = undefined;

  public startGroups: StartGroup[] = [];

  public sporteventCompetitions?: SporteventCompetition[];

  async mounted(): Promise<void> {
    this.sportevent = SporteventService.get();    
    if(this.sportevent !== undefined && this.sportevent.id !== undefined){
      let scs = await SporteventCompetitionService.getBySportevent(this.sportevent.id);
      if(scs !== undefined){
        this.sporteventCompetitions = scs;
      }
      let startgroups = await StartGroupService.getAllBySportevent()
      if(startgroups === undefined || startgroups.length === 0){
        startgroups = await StartGroupService.getAllBySporteventWithApi(this.sportevent.id);
      }
      this.startGroups = StartGroupService.sortStartGroups(startgroups);
    }
    
    this.loading = false;
  }
}
