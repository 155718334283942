var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(!_vm.loading)?_c('div',{staticClass:"startgroup"},_vm._l((_vm.disciplineCategories),function(disciplineCategory){return _c('table',{key:disciplineCategory.id,staticClass:"discipline-category"},[_vm._m(0,true),_c('thead',[_c('tr',[_c('th',{staticClass:"text-center startgroup-header",attrs:{"colspan":"4"}},[_vm._v(" "+_vm._s(_vm.startgroup.StartGroupName)),_c('br'),_vm._v(" "+_vm._s(disciplineCategory.name)+" ")])]),_vm._m(1,true)]),_c('tbody',_vm._l((_vm.startgroup.starters),function(starter){return _c('tr',{key:starter.id},[_vm._l(([
              _vm.getDisciplineFromStarter(starter, disciplineCategory),
            ]),function(discipline){return [_c('td',{staticClass:"position",staticStyle:{"width":"1%"}},[(discipline.judgeType.shortname != 'SW')?_c('span',[_vm._v(_vm._s(starter.positionInStartgroup)+".")]):_vm._e()]),_c('td',{class:`style-${discipline.judgeType.shortname}`,attrs:{"colspan":discipline.judgeType.shortname == 'SW' ? 2 : 1}},[(discipline.judgeType.shortname == 'SW')?_c('span',[_c('br'),_c('br'),_vm._v(_vm._s(_vm.startgroup.StartGroupName)+" - Bahn "+_vm._s(starter.positionInStartgroup)),_c('br'),_c('br'),_vm._v(_vm._s(discipline.name)),_c('br'),_c('br')]):_vm._e(),_c('strong',[_vm._v(_vm._s(starter.person.firstname)+" "+_vm._s(starter.person.lastname))]),(discipline.judgeType.shortname == 'SW')?_c('span',[_c('br'),_c('br')]):_c('span',[_vm._v("-")]),_vm._v(" "+_vm._s(starter.startsForSociety.shortname)),_c('br'),_vm._v(" "+_vm._s(starter.sporteventCompetition.competition.name)),(discipline.judgeType.shortname == 'SW')?_c('span',[_c('br'),_c('br')]):_vm._e()]),(discipline.judgeType.shortname != 'SW')?_c('td',{staticClass:"text-center discipline-inline"},[_vm._v(" "+_vm._s(discipline.name)+" ")]):_vm._e(),_c('td',_vm._l((discipline.repetition),function(repetition){return _c('div',{key:repetition,staticClass:"values",class:`style-${discipline.judgeType.shortname}`},[_c('div',{class:`repetition-${discipline.repetition}`},[(discipline.hasPenaltyPoints)?_c('div',{staticClass:"penalty-points"},[(discipline.judgeType.shortname == 'SW')?_c('span',[_vm._v("Strafsek.")]):_vm._e(),(discipline.judgeType.shortname != 'SW')?_c('span',[_vm._v(_vm._s(discipline.unit.shortname))]):_vm._e(),(discipline.hasBPoints)?_c('div',{staticClass:"penalty-points-desc"},[_vm._v(" Sturz ")]):_vm._e(),(
                        !discipline.hasBPoints &&
                        discipline.judgeType.shortname != 'SW'
                      )?_c('div',{staticClass:"penalty-points-desc"},[_vm._v(" Strafe ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"a-value"},[(discipline.judgeType.shortname == 'SW')?_c('span',[_vm._v("Schwimmzeit")]):_vm._e(),(discipline.judgeType.shortname != 'SW')?_c('span',[_vm._v(_vm._s(discipline.unit.shortname))]):_vm._e(),(discipline.hasBPoints)?_c('div',{staticClass:"b-value-desc"},[_vm._v(" A-Note ")]):_vm._e(),(!discipline.hasBPoints)?_c('div',{staticClass:"b-value-desc"}):_vm._e()]),(discipline.hasBPoints)?_c('div',{staticClass:"b-value"},[_vm._v(" "+_vm._s(discipline.unit.shortname)+" "),(discipline.hasBPoints)?_c('div',{staticClass:"b-value-desc"},[_vm._v(" B-Note ")]):_vm._e()]):_vm._e(),(discipline.hasBPoints)?_c('div',{staticClass:"b-value"},[_vm._v(" "+_vm._s(discipline.unit.shortname)+" "),(discipline.hasBPoints)?_c('div',{staticClass:"b-value-desc"},[_vm._v(" B-Note ")]):_vm._e()]):_vm._e()])])}),0)]})],2)}),0)])}),0):_vm._e(),(_vm.loading)?_c('vue-loading'):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('colgroup',[_c('col',{staticStyle:{"width":"3%"},attrs:{"span":"1"}}),_c('col',{staticStyle:{"width":"30%"},attrs:{"span":"1"}}),_c('col',{staticStyle:{"width":"10%"},attrs:{"span":"1"}}),_c('col',{staticStyle:{"width":"67%"},attrs:{"span":"1"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',[_c('th'),_c('th',[_vm._v("Starter")]),_c('th'),_c('th',{staticClass:"text-center"},[_vm._v("Leistung")])])
}]

export { render, staticRenderFns }