
import { Component, Vue, Prop } from "vue-property-decorator";

import Multiselect from "vue-multiselect";

import StartGroup from "../../models/sportevent/StartGroup";
import Discipline from "../../models/sporteventCalculation/Discipline";
import SporteventCompetition from "../../models/sportevent/SporteventCompetition";
import DisciplineCategory from "../../models/sporteventCalculation/DisciplineCategory";
import StartGroupService from "../../services/sportevent/StartGroupService";
import DisciplineService from "@/services/sporteventCalculation/DisciplineService";
import Sportevent from "@/models/sportevent/Sportevent";
import Starter from "@/models/sportevent/Starter";

@Component({
  components: {
    Multiselect,
  },
})
export default class ListScoringSheetTableComponent extends Vue {
  public loading = true;

  @Prop({ required: true })
  public sportevent!: Sportevent;

  @Prop({ required: true })
  public startgroup!: StartGroup;

  @Prop({ required: true })
  public sporteventCompetitions!: SporteventCompetition[];

  public disciplines: Discipline[] = [];
  public disciplineCategories: DisciplineCategory[] = [];
  public firstDiscipline?: Discipline = undefined;
  public currentDiscipline?: Discipline;

  async mounted(): Promise<void> {
    this.disciplines = await StartGroupService.getDisciplinesFromStartGroup(
      this.startgroup,
      this.sporteventCompetitions
    );
    this.disciplineCategories = await DisciplineService.getDisciplineCategories(
      this.disciplines
    );
    this.loading = false;
  }

  getDisciplineFromStarter(
    starter: Starter,
    disciplineCategory: DisciplineCategory
  ): Discipline {
    let sc = this.sporteventCompetitions.find(
      (s: SporteventCompetition) => s.id === starter.sporteventCompetition.id
    );
    if (sc === undefined) {
      sc = this.sporteventCompetitions.find(
        (s: SporteventCompetition) =>
          s.competition.id === starter.sporteventCompetition.competition.id
      );
    }
    if (sc !== undefined) {
      let discipline = sc.disciplines.find(
        (d: Discipline) => d.disciplineCategory.id === disciplineCategory.id
      );
      if (discipline !== undefined) {
        if (this.firstDiscipline === undefined) {
          this.firstDiscipline = discipline;
        }
        return discipline;
      }
      let regexArray =
        /(?<distance>\d+)\s+(?<discipline_name>\w+).*(Auswahl)/.exec(
          sc.competition.name
        );
      if (
        regexArray !== null &&
        regexArray !== undefined &&
        regexArray.groups !== undefined
      ) {
        const competition = `${regexArray.groups.distance} ${regexArray.groups.discipline_name}`;
        sc = this.sporteventCompetitions.find(
          (s: SporteventCompetition) =>
            s.competition.name.includes(competition) === true
        );
        discipline = sc?.disciplines.find(
          (d: Discipline) => d.disciplineCategory.id === disciplineCategory.id
        );
        if (discipline !== undefined) {
          if (this.firstDiscipline === undefined) {
            this.firstDiscipline = discipline;
          }
          return discipline;
        }
      }
    }

    return new Discipline();
  }
}
